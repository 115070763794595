import React from "react"
import * as Sentry from '@sentry/browser';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { CurrentUser, GlobalQueryStringReader, SerializedCurrentUser } from "@Models";
import queryString from 'query-string';
import * as Authentication from "@Services/Authentication";
import { navigate } from "gatsby";

const queryParameters = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {};
// NOTE the atob() warning is because it doesn't support unicode: https://base64.guru/developers/javascript/examples/unicode-strings
const globalParams = new GlobalQueryStringReader((queryParameters || {}) as {
    [key: string]: string | string[] | null | undefined;
}, s => atob(s));

const isMarketingMirrorDomain = typeof window !== 'undefined' && !window.location.hostname.includes('loxly.io') && !window.location.hostname.includes('localhost');

console.log('isMarketingMirrorDomain: ' + isMarketingMirrorDomain);

if (globalParams.isFederatedSignin) {
    console.log('Completing federated sign in');
    Authentication.attachOneTimeLoginListener(user => {
        // ReactGA.set({ userId: user.sub });
        // ReactGA.event({ category: 'sign-in', action: 'Provider Sign In Complete', label: '-' });
        console.log('Provider sign in complete');
        if (typeof window !== 'undefined') window.dispatchEvent(new CustomEvent<SerializedCurrentUser>('loggedin', {
            detail: user!.serialize()
        }));
        navigate('/account/confirm');
    });
}


const ErrorFallback: React.FC<FallbackProps> = props => {
    return <div role="alert">
        <p>Something went wrong:</p>
        <pre>{props.error.message}</pre>
    </div>
}

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    Sentry.configureScope((scope) => {
        Object.keys(error).forEach(key => {
            scope.setExtra(key, (error as any)[key]);
        });
    });
    Sentry.captureException(error);
}


type Props = {
    isBrowser: boolean;
    children: JSX.Element[] | JSX.Element
};


const TEST_USER: CurrentUser = new CurrentUser("abd-123-fff-ggg", "user@example.com", "Jamesingtonlongname Testington", "111-222-333", 0.7);

// NOTE: useStates in this component are preserved across route changes
export const RootAppWrapper: React.FunctionComponent<Props> = (props: Props) => {

    return <div className="context-wrapper">
        <ErrorBoundary
            onError={myErrorHandler}
            FallbackComponent={ErrorFallback}>
            {props.children}
        </ErrorBoundary>
    </div>
}

