import { base64DecodeUrl } from "@jcharlesworthuk/your-mum-core/dist/functions";

type QueryUrlActions = "confirm" | "set-password" | "unsubscribe";

export class GlobalQueryStringReader {
    
    /**
     * For filtering in GA
     */
    public referralId?: string;

    /**
     * Has this request come from a Google/Facebook oauth flow?
     */
    public isFederatedSignin?: boolean;

    /**
     * A custom url action such as confirming email or unsubscribing
     */
    public action?: QueryUrlActions;

    /**
     * The email address this action came from
     */
    public actionEmail?: string;

    /**
     * Token passed in the url for whatever reason
     */
    public token?: string;

    /** 
     * ID of the file to open in Google Drive
     */
    public googleFileId?: string;

    constructor(
        private readonly queryParameters: {
            [key: string]: string | string[] | null | undefined;
        },
        private readonly atobFn: (date: string) => string
    ) {
        this.referralId = queryParameters['pr'] as string | undefined;
        this.isFederatedSignin = queryParameters['code'] ? true : false;
        this.action = queryParameters['action'] as QueryUrlActions | undefined;
        this.token = queryParameters['t'] as string | undefined
        this.googleFileId = queryParameters['gid'] as string | undefined
        if (this.action) {
            const email = queryParameters['ae'] as string | undefined;
            if (email) {
                this.actionEmail = atobFn(base64DecodeUrl(email));
            }
        }
    }

    public static makeEmailAndAction(action: QueryUrlActions, base64Email: string, token: string) {
        return `action=${action}&ae=${base64Email}&t=${token}`;
    }
}

