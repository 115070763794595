export type AwsSettingsConfig = {
    /**
     * Cognito user pool ID
     */
    userPoolId: string,
    /**
     * Cognito web client
     */
    userPoolWebClientId: string,
    /**
     * API Gateway name
     */
    apiGatewayName: string;
}


const testEnvironmentAwsConfig: AwsSettingsConfig = {
    userPoolId: 'XXXXXX',
    apiGatewayName: 'XXXXXX',
    userPoolWebClientId: 'XXXXXX',
}

const liveEnvironmentAwsConfig: AwsSettingsConfig = {
    userPoolId: 'XXXXXX',
    apiGatewayName: 'XXXXXX',
    userPoolWebClientId: 'XXXXXX',
}

export {
    testEnvironmentAwsConfig,
    liveEnvironmentAwsConfig
}