import { AwsSettingsConfig, liveEnvironmentAwsConfig, testEnvironmentAwsConfig } from "./aws-settings";

const commonConfig = {
    gaId: 'TODO',
    awsRegion: 'eu-west-2',
    identityPoolId: 'eu-west-2:XXXXXX',
    /**
     * Used as the base path for images and other static assets.
     */
    relativeAssetPath: "/",

    /**
     * Used to make an absolute url from a relative one.  
     */
    absoluteSitePath: "https://officeplan.app",

    sentryDsn: 'TODO',
}

type EnvironmentSpecificFrontendConfig = AwsSettingsConfig & {
    /**
     * The cookies are used by Cognito
     */
     cookieDomain: string;

     /**
     * This gets used in the client side as the base URL for making API requests
     */
    apiUrl: string;

    /**
     * Base url of the website, for things like the AWS Amplify setup
     */
    websiteUrl: string;

    /**
     * Url of the website but for localhost it will be robin.officeplan.app
     */
     websiteUrlNeverLocal: string;

    oauthDomain: string;

    htmlBucketName: string;
}

const testApiConfig: EnvironmentSpecificFrontendConfig = {
    ...testEnvironmentAwsConfig,
    websiteUrl: 'https://robin.officeplan.app',
    websiteUrlNeverLocal: 'https://robin.officeplan.app',
    cookieDomain: "officeplan.app",
    apiUrl: "https://robin-api.officeplan.app",
    oauthDomain: 'login-test.officeplan.app',
    htmlBucketName: 'test.officeplan.app',
}

const liveApiConfig: EnvironmentSpecificFrontendConfig = {
    ...liveEnvironmentAwsConfig,
    websiteUrl: 'https://officeplan.app',
    websiteUrlNeverLocal: 'https://officeplan.app',
    cookieDomain: "officeplan.app",
    apiUrl: "https://api.officeplan.app",
    oauthDomain: 'login.officeplan.app',
    htmlBucketName: 'officeplan.app',
}

const localApiConfig: EnvironmentSpecificFrontendConfig = {
    cookieDomain: "localhost",
    apiUrl: "http://localhost:5335/test",
    websiteUrl: 'http://localhost:5330',
    websiteUrlNeverLocal: testApiConfig.websiteUrlNeverLocal,
    userPoolId: testApiConfig.userPoolId,
    oauthDomain: testApiConfig.oauthDomain,
    userPoolWebClientId: testApiConfig.userPoolWebClientId,
    apiGatewayName: testApiConfig.apiGatewayName,
    htmlBucketName: testApiConfig.htmlBucketName
}

let proc = typeof process === 'undefined' ? { env: { stage: ''}} : process;
const config: typeof commonConfig & EnvironmentSpecificFrontendConfig = 
    __USE_API__ === 'local' ? {
        ...commonConfig,
        ...localApiConfig
    } : __USE_API__ === 'test' || proc.env.stage === 'test' ? {
        ...commonConfig,
        ...testApiConfig,
        cookieDomain: __GATSBY_ENV__ === 'develop' ? 'localhost' : testApiConfig.cookieDomain
    } : {
        ...commonConfig,
        ...liveApiConfig,
        cookieDomain: __GATSBY_ENV__ === 'develop' ? 'localhost' : liveApiConfig.cookieDomain
    };

export default config;
