import { CurrentUser } from "@Models";
import { API, Storage } from "aws-amplify";
import config from '../../frontendConfig';
import { UserDto } from "@Dtos";
import { generateNewId, makeSlug } from "@jcharlesworthuk/your-mum-core/dist/functions";

export const apiName = config.apiGatewayName;

export class PrivateApiClient {
    public withUser(user: CurrentUser) {
        return new PrivateApiClientWithUser(user);
    }

    async getMe(): Promise<UserDto | undefined> {
        console.log('Getting ME from API');
        const dto = await API.get(apiName, `/me`, {}) as UserDto;
        return dto;
    }
}

class PrivateApiClientWithUser {
    constructor(public user: CurrentUser) {

    }

}

export class PublicApiClient {

}

// Not an actual API - gets from S3
export class StaticDataApi {

    public withUser(user: CurrentUser) {
        return new StaticDataApiWithUser(user);
    }
}


class StaticDataApiWithUser {
    constructor(public user: CurrentUser) {
    }


}


export const publicApiClient = new PublicApiClient();
export const staticDataApi = new StaticDataApi();
