import { UserDto, LocalStorageUserDto, LocalStorageUserDtoWriteModel, CognitoPoolUser } from "@Dtos";
import { isNullOrEmpty } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { IHasProfileImageCircle } from "./IHasProfileImageCircle";

export type SerializedCurrentUser = {
    sub: string,
    email: string,
    fullName: string,
    identityId: string,
    indicatorHue: number
}

export class CurrentUser implements IHasProfileImageCircle {
    
    constructor(
        public sub: string,
        public email: string,
        public fullName: string,
        public identityId: string,
        public indicatorHue: number)
    { }

    get shorterDisplayName() {
        return this.fullName.length > 10 ? this.fullName.split(' ')[0] : this.fullName;
    }

    static deserialize(json: SerializedCurrentUser) {
        return new CurrentUser(json.sub, json.email, json.fullName, json.identityId, json.indicatorHue);
    }

    serialize(): SerializedCurrentUser {
        return {
            sub: this.sub,
            email: this.email,
            fullName: this.fullName,
            identityId: this.identityId,
            indicatorHue: this.indicatorHue
        };
    }

    mergeWith(writeModel: LocalStorageUserDtoWriteModel) {
        return new CurrentUser(
            this.sub,
            this.email,
            this.fullName,
            this.identityId,
            writeModel.indicatorHue || this.indicatorHue
        );
    }

    createLocalStorageUserDto() : LocalStorageUserDto {
        return {
            identityId: this.identityId,
            indicatorHue: this.indicatorHue
        };
    }

    public static buildFromDto(dto: UserDto) {
        return new CurrentUser(
            dto.sub,
            dto.email,
            dto.fullName,
            dto.identityId,
            dto.indicatorHue);
    }

    static buildFromCognioAndLocalStorage(cognitoUser: CognitoPoolUser, localStorageUser: LocalStorageUserDto): CurrentUser {
        return new CurrentUser(cognitoUser.username,
            cognitoUser.attributes.email,
            cognitoUser.attributes.name,
            localStorageUser.identityId,
            localStorageUser.indicatorHue
        );
    }
}