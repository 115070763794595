export const ScreenHintTypes = <const>['zoom', 'create-location', 'enter-name'];

export type ScreenHintsTotal = {
    [key in typeof ScreenHintTypes[number]]: number
}

export type ScreenHintsThisSession = {
    [key in typeof ScreenHintTypes[number]]: boolean
}

export const emptyScreenHintDictionary: ScreenHintsThisSession = {
    "create-location": false,
    "enter-name": false,
    "zoom": false
}

export const emptyScreenHintsTotalDictionary: ScreenHintsTotal = {
    "create-location": 0,
    "enter-name": 0,
    "zoom": 0
}