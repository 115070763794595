exports.components = {
  "component---src-layouts-article-list-page-layout-tsx": () => import("./../../../src/layouts/ArticleListPageLayout.tsx" /* webpackChunkName: "component---src-layouts-article-list-page-layout-tsx" */),
  "component---src-layouts-article-page-layout-tsx-content-file-path-opt-build-repo-marketing-site-content-articles-10-office-seating-layout-ideas-mdx": () => import("./../../../src/layouts/ArticlePageLayout.tsx?__contentFilePath=/opt/build/repo/marketing-site/content/articles/10-office-seating-layout-ideas.mdx" /* webpackChunkName: "component---src-layouts-article-page-layout-tsx-content-file-path-opt-build-repo-marketing-site-content-articles-10-office-seating-layout-ideas-mdx" */),
  "component---src-layouts-article-page-layout-tsx-content-file-path-opt-build-repo-marketing-site-content-articles-navigating-hybrid-work-environments-with-seating-plans-mdx": () => import("./../../../src/layouts/ArticlePageLayout.tsx?__contentFilePath=/opt/build/repo/marketing-site/content/articles/navigating-hybrid-work-environments-with-seating-plans.mdx" /* webpackChunkName: "component---src-layouts-article-page-layout-tsx-content-file-path-opt-build-repo-marketing-site-content-articles-navigating-hybrid-work-environments-with-seating-plans-mdx" */),
  "component---src-layouts-links-page-layout-tsx": () => import("./../../../src/layouts/LinksPageLayout.tsx" /* webpackChunkName: "component---src-layouts-links-page-layout-tsx" */),
  "component---src-layouts-marketing-page-layout-tsx-content-file-path-opt-build-repo-marketing-site-content-landing-home-page-mdx": () => import("./../../../src/layouts/MarketingPageLayout.tsx?__contentFilePath=/opt/build/repo/marketing-site/content/landing/home-page.mdx" /* webpackChunkName: "component---src-layouts-marketing-page-layout-tsx-content-file-path-opt-build-repo-marketing-site-content-landing-home-page-mdx" */),
  "component---src-layouts-marketing-page-layout-tsx-content-file-path-opt-build-repo-marketing-site-content-landing-return-to-office-mdx": () => import("./../../../src/layouts/MarketingPageLayout.tsx?__contentFilePath=/opt/build/repo/marketing-site/content/landing/return-to-office.mdx" /* webpackChunkName: "component---src-layouts-marketing-page-layout-tsx-content-file-path-opt-build-repo-marketing-site-content-landing-return-to-office-mdx" */),
  "component---src-layouts-static-page-layout-tsx-content-file-path-opt-build-repo-marketing-site-content-static-pages-cookie-policy-mdx": () => import("./../../../src/layouts/StaticPageLayout.tsx?__contentFilePath=/opt/build/repo/marketing-site/content/static-pages/cookie-policy.mdx" /* webpackChunkName: "component---src-layouts-static-page-layout-tsx-content-file-path-opt-build-repo-marketing-site-content-static-pages-cookie-policy-mdx" */),
  "component---src-layouts-static-page-layout-tsx-content-file-path-opt-build-repo-marketing-site-content-static-pages-privacy-policy-mdx": () => import("./../../../src/layouts/StaticPageLayout.tsx?__contentFilePath=/opt/build/repo/marketing-site/content/static-pages/privacy-policy.mdx" /* webpackChunkName: "component---src-layouts-static-page-layout-tsx-content-file-path-opt-build-repo-marketing-site-content-static-pages-privacy-policy-mdx" */),
  "component---src-layouts-static-page-layout-tsx-content-file-path-opt-build-repo-marketing-site-content-static-pages-terms-of-service-mdx": () => import("./../../../src/layouts/StaticPageLayout.tsx?__contentFilePath=/opt/build/repo/marketing-site/content/static-pages/terms-of-service.mdx" /* webpackChunkName: "component---src-layouts-static-page-layout-tsx-content-file-path-opt-build-repo-marketing-site-content-static-pages-terms-of-service-mdx" */)
}

