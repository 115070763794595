import React from "react";
import type { GatsbyBrowser } from "gatsby";
import { RootAppWrapper } from "./src/RootAppWrapper";
import configureAws from "@Services/AwsAmplify"
import { hardLoadMeFromApi, loadUserFromStorage } from "./src/services/Authentication";
import { LoggedInActionType } from "./src/contexts/LoggedInContext";
import { GlobalLoxlyActionType } from "@Contexts/GlobalAppContext";
import { SerializedCurrentUser } from "@Models";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return <RootAppWrapper isBrowser={true}>{element}</RootAppWrapper>
}

export const onInitialClientRender: GatsbyBrowser["onInitialClientRender"] = () => {
  return; // TODO when you have cognito re-enable this!
  configureAws().then(() => {
    loadUserFromStorage().then((cachedUser) => {
      if (typeof window !== 'undefined') window.dispatchEvent(new Event('awsConfigured'));
      if (cachedUser) {
          console.log(`Found user in local storage: dispatching auth action.`);
          console.log(cachedUser);
          if (typeof window !== 'undefined') window.dispatchEvent(new CustomEvent<SerializedCurrentUser>('loggedin', {
            detail: cachedUser!.serialize()
          }));
      } else {
        if (typeof window !== 'undefined') window.dispatchEvent(new Event('loggedout'));
      }
    }).catch(e => {
      console.error(e);
      console.log(`There was an error running loadUserFromStorage() from gatsby-browser`);
    });;
  }).catch(e => {
    console.error(e);
    console.log(`There was an error running configureAws() from gatsby-browser`);
  });
}

export const onServiceWorkerUpdateReady: GatsbyBrowser["onServiceWorkerUpdateReady"] = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
    `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}