// import Amplify from "@Plugins/amplify-core";
import { Amplify } from 'aws-amplify';
import { apiName } from "./ApiClient";
import config from '../../frontendConfig';

console.log('USING AMPLIFY CONFIG:', config);
console.log('__GATSBY_ENV__ = ' + __GATSBY_ENV__);

export default async function configureAws() {
    await Amplify.configure({
        Auth: {
            region: config.awsRegion,
            userPoolId: config.userPoolId,
            userPoolWebClientId: config.userPoolWebClientId,
            identityPoolId: config.identityPoolId,
            oauth: {
                mandatorySignIn: true,
                domain: config.oauthDomain,
                scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
                responseType: 'code',
                redirectSignIn: config.websiteUrl,
                redirectSignOut: config.websiteUrl + '/account/logout',
            },
            federationTarget: "COGNITO_USER_POOLS",
            cookieStorage: {
                domain: config.cookieDomain,
                path: '/',
                expires: 365,
                secure: false //TODO make true
            },
        },
        API: {
            endpoints: [
                {
                    name: apiName,
                    endpoint: config.apiUrl,
                    region: config.awsRegion,
                    // custom_header: async () => { 
                    //     return { 'x-loxly-version' : __FRONTEND_VERSION__ }  //This will work but you need to allow it in CORS on the api gateway by putting Access-Control-Allow-Headers in each buildResponse(...) in the lambdas
                    // }
                },
            ]
        },
        Storage: {
            AWSS3: {
                bucket: config.htmlBucketName,
                region: config.awsRegion,
                identityPoolId: config.identityPoolId,
            }
        }
    });
}