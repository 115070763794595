import { CurrentUser } from "@Models";
import { Auth, Hub } from 'aws-amplify';
import { HubCallback } from '@aws-amplify/core/lib/Hub'
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib";
import { PrivateApiClient } from "./ApiClient";
import { CognitoPoolUser, LocalStorageUserDto, LocalStorageUserDtoWriteModel, UserDto } from "@Dtos";

export const isBrowser = () => typeof window !== "undefined";

export async function getCognitoUser() {
    try {
        const result = await Auth.currentUserInfo() as CognitoPoolUser;
        return result;
    } catch (e: any) {
        if (e === "not authenticated" || e === "The user is not authenticated" || e.toString().includes('not authenticated')) {
            return undefined;
        } else {
            throw e;
        }
    }
}

export async function isCognitoLoggedIn() {
    var user = await getCognitoUser();
    return user ? true : false;
}




export function getLocalStorageUser() {
    if (!localStorage) return undefined;
    const cachedDto = localStorage.getItem("me");
    if (!cachedDto || cachedDto.length == 0) return undefined;
    const dto = JSON.parse(cachedDto) as LocalStorageUserDto;
    return dto;
}

export function saveLocalStorageUser(obj: LocalStorageUserDto) {
    if (!localStorage) return undefined;
    const trimToOnly: LocalStorageUserDto = {
        identityId: obj.identityId,
        indicatorHue: obj.indicatorHue
    };
    const serialised = JSON.stringify(trimToOnly);
    localStorage.setItem("me", serialised);
    console.log('Saved me in localstorage');
}


export async function clearLocalStorageUser() {
    if (!localStorage) return undefined;
    console.log('Removing me from localstorage');
    localStorage.removeItem("me");
}



export async function loadUserFromStorage() {
    if (!isBrowser()) return undefined;

    const cognitoUser = await getCognitoUser();

    if (!cognitoUser) return undefined;

    let localStorageUser = getLocalStorageUser();

    if (!localStorageUser) {
        //This can happen during the Provider login (eg Google) because the user loading code in gatsby-browser.js tries to run at the same time as the 
        //attachOneTimeLoginListener() stuff.  This is fine just let gatsby-browser.js finish with no user
        //console.error('Cognito user was found but there was no user in the local storage!');
        return undefined;
    }

    return CurrentUser.buildFromCognioAndLocalStorage(cognitoUser, localStorageUser);
}

export async function signInWithPassword(email: string, password: string) {
    console.log('signing in...');
    await Auth.signIn(email.toLowerCase(), password);
    console.log('User signed in');
    const cognitoUser = await getCognitoUser();
    if (!cognitoUser) throw new Error('Error - logged in ok but cognito not returning the user');
    console.log(cognitoUser);

    return await loadMeAndSaveLocally(localStorageUser => CurrentUser.buildFromCognioAndLocalStorage(cognitoUser, localStorageUser));
}


export async function signInWithProvider(provider: CognitoHostedUIIdentityProvider) {
    await Auth.federatedSignIn({ provider });
}


export async function signInAndChangePassword(email: string, tempPassword: string, newPassword: string) {
    console.log('signing in with temp password...');
    const loggedInUser = await Auth.signIn(email.toLowerCase(), tempPassword);
    if (!loggedInUser) throw new Error('Error - failed to sign in');
    console.log('User signed in');
    console.log(loggedInUser);
    await Auth.completeNewPassword(loggedInUser, newPassword, {});
    const cognitoUser = await getCognitoUser();
    if (!cognitoUser) throw new Error('Error - logged in ok but cognito not returning the user');
    console.log(cognitoUser);
    return await loadMeAndSaveLocally(localStorageUser => CurrentUser.buildFromCognioAndLocalStorage(cognitoUser, localStorageUser));
}

export async function hardLoadMeFromApi() {
    if (!isBrowser()) return undefined;

    const cognitoUser = await getCognitoUser();

    if (!cognitoUser) return undefined;

    let localStorageUser = getLocalStorageUser();

    if (!localStorageUser) {
        return undefined;
    }
    return await loadMeAndSaveLocally(localStorageUser => CurrentUser.buildFromCognioAndLocalStorage(cognitoUser, localStorageUser));
}

async function loadMeAndSaveLocally(buildUser: (dto: LocalStorageUserDto & UserDto) => CurrentUser) {
    const userDto = await new PrivateApiClient().getMe();
    if (!userDto) throw new Error('No dto returned from /me!');
    const localStorageUser: LocalStorageUserDto & UserDto = {
        ...userDto,
    };
    saveLocalStorageUser(localStorageUser);
    const user = buildUser(localStorageUser);
    return user;
}

export function updateLocallyStoredUser(writeModel: LocalStorageUserDtoWriteModel) {
    if (!localStorage) return undefined;
    let localStorageUser = getLocalStorageUser();
    if (!localStorageUser) throw new Error('Cannot update local user, no user found in local storage');
    const newVersion: LocalStorageUserDto = {
        ...localStorageUser,
        ...writeModel
    };
    const serialised = JSON.stringify(newVersion);
    localStorage.setItem("me", serialised);
    console.log('Saved me in localstorage');
}



//TODO needed but it can't run server-side so fix that (it runs when the file is loaded which is a no-no for SSR)
let loginCallback: (user: CurrentUser) => void = () => { };
const loginListener: HubCallback = (data) => {
    //console.log('Amplify: ' + data.payload.event);
    if (data.payload.event == 'signIn') {
        console.log('HUB login heard');
        loadMeAndSaveLocally(dto => CurrentUser.buildFromDto(dto)).then(user => {
            if (!user) throw new Error('Amplify hub event heard a login but prvate API returned no user');
            console.log('HUB Got user from API');
            loginCallback(user);
            loginCallback = () => { };
            Hub.remove('auth', loginListener as any);
        })
    }
}


export function attachOneTimeLoginListener(callback: (user: CurrentUser) => void) {
    loginCallback = callback;
    Hub.listen('auth', loginListener);
}


export async function registerWithCognito(request: { email: string, password: string, displayName: string }) {
    await Auth.signUp({
        username: request.email.toLowerCase(),
        password: request.password,
        attributes: {
            email: request.email.toLowerCase(),
            name: request.displayName,
        }
    });
}

export async function confirmRegistration(email: string, code: string) {
    await Auth.confirmSignUp(email, code);
}

export async function resendRegisterCode(email: string) {
    await Auth.resendSignUp(email);
}


export async function resendVerificationEmail(username: string) {
    await Auth.resendSignUp(username.toLowerCase());
}

export async function requestPasswordResetCode(username: string) {
    await Auth.forgotPassword(username.toLowerCase());
}

export async function submitNewPassword(username: string, code: string, password: string) {
    await Auth.forgotPasswordSubmit(username.toLowerCase(), code, password)
}

/**
 * You must be logged in to do this!
 */
export async function changePasswordWithPrevious(oldPassword: string, newPassword: string) {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
}

export function signOut() {
    Auth.signOut();
    clearLocalStorageUser();
}